import Vue from 'vue'

export default {
    fetchRolesDictionary: function () {
        return Vue.$http.get('dictionaries/roles');
    },

    fetchServicesDictionary: function () {
        return Vue.$http.get('dictionaries/services');
    },

    fetchPermissionsDictionary: function () {
        return Vue.$http.get('dictionaries/permissions');
    },

    fetchRolePermissions: function (role_id) {
        return Vue.$http.get('dictionaries/role/' + role_id + '/permissions');
    },

    updateRolePermissions: function (role_id, permissions) {
        return Vue.$http.patch('dictionaries/role/' + role_id + '/permissions', {permissions: permissions});
    },

    fetchCaretakersDictionary: function () {
        return Vue.$http.get('dictionaries/caretakers');
    },

    fetchAgentsDictionary: function () {
        return Vue.$http.get('dictionaries/agents');
    },

    fetchRecruitersDictionary: function () {
        return Vue.$http.get('dictionaries/recruiters');
    },

}
