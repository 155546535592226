var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers.filter(function (header) { return header.visibleFor === _vm.role || _vm.role === 'admin'; }),"flat":"","items":_vm.invoices.data,"items-per-page":10},scopedSlots:_vm._u([{key:"item.receiver",fn:function(ref){
var item = ref.item;
return [(item.receiver)?_c('div',[_c('h1',{staticClass:"mb-0 mt-2 text-xl-h6",on:{"click":function($event){return _vm.$router.push(item.receiver_type+'/'+item.receiver_id)}}},[_vm._v(" "+_vm._s(item.receiver.full_name)+" ")]),_c('p',{staticClass:"head-m-0"},[_c('small',[_vm._v(_vm._s(item.receiver.email))])]),_c('v-chip',{staticClass:"text-capitalize mb-3",attrs:{"label":"","x-small":"","color":"primary"}},[_vm._v(_vm._s(item.receiver_type.slice(0, -1))+" ")])],1):_c('div',[_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("None")])],1)]}},{key:"item.approver",fn:function(ref){
var item = ref.item;
return [(item.approver)?_c('div',[_c('h1',{staticClass:"mb-0 text-xl-h6"},[_vm._v(_vm._s(item.approver.full_name))]),_c('p',{staticClass:"ma-0"},[_c('small',[_vm._v(_vm._s(item.approver.email))])])]):_c('div',[_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("None")])],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.amount > 0 ? 'success' : 'warning'}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.amount)+" € ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.type))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-sm-6 my-3"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD MMM YY")))])]}}])}),_c('v-pagination',{attrs:{"length":_vm.invoices.last_page,"total-visible":6},on:{"input":_vm.paginationChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('invoices-create-popup')],1)}
var staticRenderFns = []

export { render, staticRenderFns }